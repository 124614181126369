import React, {ElementType, ReactNode} from 'react'
import {css, useTheme} from '@emotion/react'
import styled from '@emotion/styled'
import {ColorPath} from 'types/Theme'

import {getColorByPath} from '../../../utils/colors'

export type TitleVariant =
  | 'titleXS'
  | 'titleS'
  | 'titleM'
  | 'titleL'
  | 'titleXL'
  | 'title2XL'
  | 'title3XL'
  | 'title4XL'
  | 'title5XL'
  | 'title6XL'

export type BodyVariant = 'bodyXS' | 'bodyS' | 'bodyM'

export type LabelVariant = 'labelXS' | 'labelS' | 'labelM'

export type TextVariant = BodyVariant | LabelVariant | TitleVariant

export type TextAlign =
  | 'center'
  | 'end'
  | 'start'
  | 'justify'
  | 'left'
  | 'right'

interface TextProps {
  /** HTML Element that text will be rendered */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  as?: ElementType<any>
  /** Specify the color path from the theme's colors file to explicitly set the Text's color */
  colorPath?: ColorPath
  /** The text variant style from the theme */
  variant?: TextVariant
  /** HTML class to be included in the tag */
  className?: string
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
  /** Text to be displayed */
  children: ReactNode
  /** text-align style */
  align?: TextAlign
  id?: string
  style?: React.CSSProperties
}

interface StyleProps {
  colorValue?: string
  variant: TextVariant
}

const withColor = ({colorValue}: StyleProps) =>
  !!colorValue &&
  css`
    color: ${colorValue};
  `

const TextElement = styled.span<TextProps & StyleProps>(
  ({theme, variant, align}) => css`
    ${theme.typography.text[variant]}
    text-align: ${align || 'unset'};
    margin: 0;
  `,
  withColor
)

const getTagName = (variant: TextVariant) => {
  if (variant === 'titleXS') {
    return 'h6'
  }

  if (variant === 'titleS') {
    return 'h5'
  }

  if (variant === 'titleM') {
    return 'h4'
  }

  if (variant === 'titleL') {
    return 'h3'
  }

  if (variant === 'titleXL') {
    return 'h2'
  }

  if (
    variant === 'title2XL' ||
    variant === 'title3XL' ||
    variant === 'title4XL' ||
    variant === 'title5XL' ||
    variant === 'title6XL'
  ) {
    return 'h1'
  }

  return 'p'
}

export const Text = ({
  as,
  colorPath,
  variant = 'bodyM',
  className,
  dataId,
  align,
  children,
  id,
  style
}: TextProps) => {
  const theme = useTheme()

  const colorValue = colorPath && getColorByPath(theme, colorPath)
  const tagName = as || getTagName(variant)

  return (
    <TextElement
      as={tagName}
      colorValue={colorValue}
      variant={variant}
      className={className}
      data-id={dataId}
      align={align}
      id={id}
      style={style}
    >
      {children}
    </TextElement>
  )
}
