import React from 'react'
import {css, Theme, useTheme} from '@emotion/react'
import styled from '@emotion/styled'
import {ColorPath, ThemeType} from 'types/Theme'

import {getColorByPath} from '../../../utils/colors'

interface Props {
  /** Card content */
  children?: React.ReactNode
  /** Pass through classname to allow styles overrides */
  className?: string
  /** Whether card should have rounded corners at the top */
  hasRoundedTop?: boolean
  /** Whether card should have rounded corners at the bottom */
  hasRoundedBottom?: boolean
  /** Radius of rounded corners */
  borderRadius?: keyof ThemeType['layout']['radius']
  /** Shows up 1px border with specified color */
  borderColorPath?: ColorPath
  /** Sets background color of Card component */
  backgroundColorPath?: ColorPath
  /** Whether card should have shadow */
  shadow?: keyof ThemeType['shadows']
  /** Whether overflowing content of the card should be hidden */
  isOverflowHidden?: boolean
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
}

interface StyledCardProps extends Props {
  backgroundColorValue?: string
  borderColorValue?: string
  theme: Theme
}

const withBaseStyles = ({
  theme,
  backgroundColorValue,
  isOverflowHidden
}: StyledCardProps) => css`
  background: ${backgroundColorValue ?? theme.colors.background.neutral.c000};
  overflow: ${isOverflowHidden ? 'hidden' : 'visible'};
`

const withBorder = ({borderColorValue}: StyledCardProps) => {
  if (!borderColorValue) {
    return null
  }
  return css`
    border: 1px solid ${borderColorValue};
  `
}

const withTopRounded = ({
  theme,
  hasRoundedTop,
  borderRadius
}: StyledCardProps) => {
  if (!hasRoundedTop) {
    return null
  }
  return borderRadius
    ? css`
        border-top-left-radius: ${theme.layout.radius[borderRadius]}px;
        border-top-right-radius: ${theme.layout.radius[borderRadius]}px;
      `
    : null
}

const withBottomRounded = ({
  theme,
  hasRoundedBottom,
  borderRadius
}: StyledCardProps) => {
  if (!hasRoundedBottom) {
    return null
  }
  return borderRadius
    ? css`
        border-bottom-left-radius: ${theme.layout.radius[borderRadius]}px;
        border-bottom-right-radius: ${theme.layout.radius[borderRadius]}px;
      `
    : null
}

const withShadow = ({shadow, theme}: Props & StyledCardProps) => {
  if (!shadow) {
    return null
  }
  return css`
    box-shadow: ${theme.shadows[shadow]};
  `
}

const CardElement = styled.div<StyledCardProps>(
  withBaseStyles,
  withBorder,
  withTopRounded,
  withBottomRounded,
  withShadow
)

export const Card = ({
  children,
  dataId,
  hasRoundedTop = true,
  hasRoundedBottom = true,
  borderRadius,
  borderColorPath,
  backgroundColorPath,
  shadow,
  isOverflowHidden = true,
  className
}: Props) => {
  const theme = useTheme()
  const borderColorValue =
    borderColorPath && getColorByPath(theme, borderColorPath)
  const backgroundColorValue =
    backgroundColorPath && getColorByPath(theme, backgroundColorPath)

  return (
    <CardElement
      hasRoundedBottom={hasRoundedBottom}
      hasRoundedTop={hasRoundedTop}
      borderRadius={borderRadius}
      borderColorValue={borderColorValue}
      backgroundColorValue={backgroundColorValue}
      isOverflowHidden={isOverflowHidden}
      shadow={shadow}
      className={className}
      data-id={dataId}
      theme={theme}
    >
      {children}
    </CardElement>
  )
}
