import {Brand} from '../types'
import {brands} from './brands'

export {brands}

export const defaultBrandCode = 'vio' // TODO (@Gerrit88) Replace all usage of defaultBrand.code with defaultBrandCode
export const defaultBrand = brands[defaultBrandCode]

const brandsByCode = Object.fromEntries(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Object.entries(brands).map(([_, config]) => [config.code, config])
)

export const getBrandByCode = (code: string): Brand => brandsByCode[code]
