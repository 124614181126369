import React, {forwardRef} from 'react'
import {css, ThemeProvider} from '@emotion/react'
import styled from '@emotion/styled'
import {ThemeType} from 'types/Theme'

import {hexToRGBAString} from '@daedalus/core/src/utils/string'

import {getTheme, ThemeNames} from '../../../../themes'
import {mq} from '../../../../utils/breakpoints'
import {
  buttonSizesMap,
  MakeStyledButtonProps,
  Props as ButtonProps,
  StyleProps
} from '../../Button'
import {LoadingBullets} from '../../Button/LoadingBullets'

interface ColorVariantProps {
  theme: ThemeType
}

export const withBaseStyles = () => {
  return css`
    border: 0;
    margin: 0;
    padding: 0;
    user-select: none;
    outline: none;
    position: relative;
    text-decoration: none;
    background: none;
    color: inherit;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  `
}

export const makeStyledButton = ({
  fontColor,
  backgroundColor,
  hoverBackgroundColor,
  activeBackgroundColor,
  borderColor,
  hoverBorderColor,
  activeBorderColor,
  variant,
  shadow
}: MakeStyledButtonProps) => css`
  ${ButtonStyledContainer} {
    color: ${fontColor ?? ''};
    background-color: ${backgroundColor ?? ''};
    border-style: solid;
    border-width: 1.5px;
    border-color: ${borderColor ?? ''};
    ${variant === 'inverse' &&
    css`
      box-shadow: ${shadow ?? ''};
      opacity: 0.9;
    `}
  }

  ${mq.desktopXs(css`
    &:hover,
    &:focus {
      ${ButtonStyledContainer} {
        background-color: ${hoverBackgroundColor ?? ''};
        border-style: solid;
        border-width: 1.5px;
        border-color: ${hoverBorderColor ?? ''};
      }
    }
  `)}

  &:active {
    ${ButtonStyledContainer} {
      background-color: ${activeBackgroundColor ?? ''};
      border-style: solid;
      border-width: 1.5px;
      border-color: ${activeBorderColor ?? ''};
      transform: scale(0.925);
    }
  }
`

export const makeStyledGradientButton = ({
  fontColor,
  backgroundColor,
  hoverBackgroundColor
}: MakeStyledButtonProps) => css`
  ${ButtonStyledContainer} {
    color: ${fontColor};
    position: relative;
    background-image: ${backgroundColor};
    z-index: 1;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: ${hoverBackgroundColor};
      z-index: -1;
      transition: opacity 0.3s linear;
      transform: translateZ(0); // Fix for transition flickering in Safari
      opacity: 0;
      border-radius: inherit;
    }
  }

  &:hover {
    ${ButtonStyledContainer} {
      &:before {
        opacity: 1;
      }
    }
  }
  &:active {
    ${ButtonStyledContainer} {
      transform: scale(0.925);
    }
  }
`

// added as part of b4f4f3cc-website-restyle-v3
export const makeStyledGradientBorderButton = ({
  fontColor,
  backgroundColor,
  hoverBackgroundColor,
  borderColor,
  activeBackgroundColor
}: MakeStyledButtonProps) => css`
  // z-index and position has been added because of linear gradient on border
  z-index: 0;
  position: relative;
  // added because of withTouchArea
  min-height: inherit;

  ${ButtonStyledContainer} {
    color: ${fontColor ?? ''};
    background-color: ${backgroundColor ?? ''};
    border: 1.5px solid transparent;
    background-clip: padding-box;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: ${borderColor};
      border-radius: inherit;
      z-index: -1;
    }
  }

  ${mq.desktopXs(css`
    &:hover,
    &:focus {
      ${ButtonStyledContainer} {
        background-color: ${hoverBackgroundColor ?? ''};
      }
    }
  `)}

  &:active {
    transform: scale(0.925);
    ${ButtonStyledContainer} {
      background-color: ${activeBackgroundColor ?? ''};
    }
  }
`

const colorPrimary = ({theme}: ColorVariantProps) => {
  const primaryColors = theme.colors.button.primary
  return makeStyledButton({
    fontColor: primaryColors.content,
    backgroundColor: primaryColors.background,
    hoverBackgroundColor: primaryColors.hover.background,
    activeBackgroundColor: primaryColors.hover.background,

    borderColor: primaryColors.border,
    hoverBorderColor: primaryColors.hover.border,
    activeBorderColor: primaryColors.hover.border
  })
}

const colorSecondary = ({theme}: ColorVariantProps) => {
  const secondaryColors = theme.colors.button.secondary

  return makeStyledButton({
    fontColor: secondaryColors.content,
    backgroundColor: secondaryColors.background,
    hoverBackgroundColor: secondaryColors.hover.background,
    activeBackgroundColor: secondaryColors.hover.background,
    borderColor: secondaryColors.border,
    hoverBorderColor: secondaryColors.hover.border,
    activeBorderColor: secondaryColors.hover.border
  })
}

const colorInverse = ({theme}: ColorVariantProps) => {
  const inverseColors = theme.colors.button.inverse

  return makeStyledButton({
    fontColor: inverseColors.content,
    backgroundColor: inverseColors.background,
    hoverBackgroundColor: inverseColors.hover.background,
    activeBackgroundColor: inverseColors.hover.background,
    borderColor: inverseColors.border,
    hoverBorderColor: inverseColors.hover.border,
    activeBorderColor: inverseColors.hover.border,
    variant: 'inverse',
    shadow: theme.shadows.floating
  })
}

const colorWarning = ({theme}: ColorVariantProps) => {
  const dangerColors = theme.colors.button.danger

  return makeStyledButton({
    fontColor: dangerColors.content,
    backgroundColor: dangerColors.background,
    hoverBackgroundColor: dangerColors.hover.background,
    activeBackgroundColor: dangerColors.hover.background,
    borderColor: dangerColors.border,
    hoverBorderColor: dangerColors.hover.border,
    activeBorderColor: dangerColors.hover.border
  })
}

const colorQuiet = ({theme}: ColorVariantProps) => {
  const quietColors = theme.colors.button.quiet

  return makeStyledButton({
    fontColor: quietColors.content,
    backgroundColor: quietColors.background,
    hoverBackgroundColor: quietColors.hover.background,
    activeBackgroundColor: quietColors.hover.background,
    borderColor: quietColors.border,
    hoverBorderColor: quietColors.hover.border,
    activeBorderColor: quietColors.hover.border
  })
}

const colorTransparent = ({theme}: ColorVariantProps) => {
  const transparentColors = theme.colors.button.transparent
  return makeStyledButton({
    fontColor: transparentColors.content,
    backgroundColor: transparentColors.background,
    hoverBackgroundColor: hexToRGBAString(
      transparentColors.hover.background,
      0.2
    ),
    activeBackgroundColor: hexToRGBAString(
      transparentColors.hover.background,
      0.2
    ),
    borderColor: transparentColors.border,
    hoverBorderColor: transparentColors.border,
    activeBorderColor: transparentColors.border
  })
}

const colorDisabled = ({theme}: {theme: ThemeType}) => {
  const disabledColors = theme.colors.button.disabled

  return makeStyledButton({
    fontColor: disabledColors.content,
    backgroundColor: disabledColors.background,
    hoverBackgroundColor: disabledColors.background,
    activeBackgroundColor: disabledColors.background,
    borderColor: disabledColors.border,
    hoverBorderColor: disabledColors.border,
    activeBorderColor: disabledColors.border
  })
}

const colorSecondaryGradient = ({theme}: {theme: ThemeType}) => {
  const secondaryGradientColor = theme.colors.button.secondaryGradient

  return makeStyledGradientBorderButton({
    fontColor: secondaryGradientColor.content,
    backgroundColor: secondaryGradientColor.background,
    hoverBackgroundColor: secondaryGradientColor.hover.background,
    borderColor: secondaryGradientColor.border,
    activeBackgroundColor: secondaryGradientColor.hover.background
  })
}

const colorPrimaryGradient = ({theme}: ColorVariantProps) => {
  const primaryGradientColor = theme.colors.button.primaryGradient

  return makeStyledGradientButton({
    fontColor: primaryGradientColor.content,
    backgroundColor: primaryGradientColor.background,
    hoverBackgroundColor: primaryGradientColor.hover.background
  })
}

export const withColor = ({
  variant,
  disabledStyle
}: StyleProps & {theme: ThemeType}) => {
  if (disabledStyle) return colorDisabled

  switch (variant) {
    case 'inverse': {
      return colorInverse
    }
    case 'secondary': {
      return colorSecondary
    }
    case 'warning': {
      return colorWarning
    }
    case 'quiet': {
      return colorQuiet
    }
    case 'transparent': {
      return colorTransparent
    }
    case 'primaryGradient': {
      return colorPrimaryGradient
    }
    case 'secondaryGradient': {
      return colorSecondaryGradient
    }
    case 'primary':
    default: {
      return colorPrimary
    }
  }
}

export const withTouchArea = ({hasTouchArea, size}: StyleProps) => {
  const {size: sizeNoTouchArea, sizeWithTouchArea} = buttonSizesMap[size]

  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: ${hasTouchArea ? sizeWithTouchArea : sizeNoTouchArea}px;
    min-width: ${hasTouchArea ? sizeWithTouchArea : sizeNoTouchArea}px;
  `
}

export const withSize = ({size, variant}: StyleProps) => {
  const {size: minSize, basePadding, horizontalPadding} = buttonSizesMap[size]
  if (variant === 'primaryGradient') {
    return css`
      min-height: ${minSize}px;
      min-width: ${minSize}px;
      // Extra padding added since we can't have a normal border for this variant
      padding: ${horizontalPadding
        ? `${basePadding + 1.5}px ${horizontalPadding + 1.5}px`
        : `${basePadding + 1.5}px`};
    `
  }
  return css`
    min-height: ${minSize}px;
    min-width: ${minSize}px;
    padding: ${horizontalPadding
      ? `${basePadding}px ${horizontalPadding}px`
      : `${basePadding}px`};
  `
}

export const withTextSize = ({
  theme,
  size
}: StyleProps & {theme: ThemeType}) => {
  const {typography} = theme

  switch (size) {
    case 'lg': {
      return css`
        ${typography.text.labelM};
      `
    }
    case 'xl': {
      return css`
        ${typography.text.labelM};
      `
    }
    case 'sm': {
      return css`
        ${typography.text.labelXS};
      `
    }
    case 'md':
    default: {
      return css`
        ${typography.text.labelS};
      `
    }
  }
}

export const withFullWidth = ({fullWidth}: StyleProps) => {
  if (fullWidth) {
    return css`
      width: 100%;

      ${ButtonStyledContainer} {
        flex-grow: 1;
      }
    `
  }

  return null
}

export const withRounded = ({
  theme,
  isRounded
}: StyleProps & {theme: ThemeType}) => {
  if (isRounded) {
    return css`
      border-radius: ${theme.layout.radius.rounded}px;
    `
  }

  return css`
    border-radius: ${theme.layout.radius.lg}px;
  `
}

export const withDisabled = ({disabled}: StyleProps) => {
  if (disabled) {
    return css`
      cursor: not-allowed;
    `
  }

  return css`
    cursor: pointer;
  `
}

export const withFloatingShadow = ({
  theme,
  isFloating,
  disabledStyle
}: StyleProps & {theme: ThemeType}) =>
  isFloating && !disabledStyle
    ? css`
        box-shadow: ${theme.shadows.floating};
      `
    : null

const ButtonElement = styled.button(
  withBaseStyles,
  withTouchArea,
  withFullWidth,
  withColor,
  withDisabled
)

// This component name is used in the ButtonGroup component
export const ButtonStyledContainer = styled.div(
  () => css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  withSize,
  withTextSize,
  withRounded,
  withFloatingShadow
)

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LabelWrapper = styled.div(
  ({size}: StyleProps) => css`
    padding: 0 ${buttonSizesMap[size].basePadding}px;
  `
)

export const NewStyleButton = forwardRef(
  (
    {
      variant = 'primary',
      size = 'lg',
      loading = false,
      disabled = false,
      fullWidth = false,
      isRounded = false,
      className = '',
      dataId = '',
      type = 'button',
      onClick,
      children,
      iconStart,
      iconEnd,
      isFloating = false,
      hasTouchArea = false,
      form
    }: ButtonProps,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    const disabledState = loading || disabled
    const disabledStyle = disabled
    const hasChildren = Boolean(children)

    return (
      <ThemeProvider theme={getTheme(ThemeNames.newvio)}>
        <ButtonElement
          ref={ref}
          data-id={dataId}
          className={className}
          variant={variant}
          size={size}
          type={type}
          disabled={disabledState}
          fullWidth={fullWidth}
          hasChildren={hasChildren}
          hasTouchArea={hasTouchArea}
          disabledStyle={disabledStyle}
          onClick={onClick}
          form={form}
        >
          <ButtonStyledContainer
            variant={variant}
            size={size}
            hasStartIcon={Boolean(iconStart)}
            hasEndIcon={Boolean(iconEnd)}
            hasChildren={hasChildren}
            isRounded={isRounded}
            isFloating={isFloating}
          >
            {loading ? (
              <LoadingBullets size={size} />
            ) : (
              <>
                {iconStart && <IconWrapper>{iconStart}</IconWrapper>}

                {children && (
                  <LabelWrapper size={size}>{children}</LabelWrapper>
                )}

                {iconEnd && <IconWrapper>{iconEnd}</IconWrapper>}
              </>
            )}
          </ButtonStyledContainer>
        </ButtonElement>
      </ThemeProvider>
    )
  }
)

// eslint-disable-next-line fp/no-mutation
NewStyleButton.displayName = 'NewStyleButton'
