import * as React from 'react'
import styled from '@emotion/styled'

import {Brand} from '@daedalus/core/src/brand/types'

interface Props {
  /** Brand configuration. It can be one of our brands or a white-label brand. */
  brand: Brand
  /** Pass through classname to allow styles overrides */
  className?: string
  /** Whether to display the compact version */
  compact?: boolean
  /** Whether to display the white variant of the logo
   *  Added as part of ab_a707ecbb-home-page-redesign--v3
   */
  white?: boolean
}

const LogoImageElement = styled('img')`
  display: block;
  margin: 0 auto;
  height: 100%;
  max-width: 100%;
  width: auto;
`
const getBrandLogo = (
  brand: Brand,
  compact: boolean,
  white: boolean
): string => {
  return compact
    ? require(`./logos/${brand.code}-logo-compact.svg`)
    : require(`./logos/${brand.code}-logo${white ? '-white' : ''}.svg`)
}

export const BrandLogo = ({
  brand,
  compact = false,
  className,
  white = false
}: Props) => (
  <LogoImageElement
    src={getBrandLogo(brand, compact, white)}
    alt={brand.name}
    title={brand.name}
    className={className}
  />
)
