import React from 'react'
import {css, keyframes} from '@emotion/react'
import styled from '@emotion/styled'

import {ButtonSize} from '.'

interface Props {
  size?: ButtonSize
  className?: string
}

const bulletRadius = {
  sm: 8,
  md: 8,
  lg: 12,
  xl: 12
}

const fadeInOut = keyframes`
  from {
    opacity: 0.3
  }

  to {
    opacity: 0.8
  }
`

export const ButtonLoadingWrapper = styled.div`
  text-align: center;
  width: 100%;
`

export const LoadingBullet = styled.div<{
  size: ButtonSize
  delay: string
}>(({theme, size, delay}) => {
  return css`
    display: inline-block;
    opacity: 0;
    border-radius: ${theme.layout.radius.rounded}px;
    background: currentColor; // inherit button font color
    height: ${bulletRadius[size]}px;
    width: ${bulletRadius[size]}px;
    animation: 0.6s infinite alternate ${fadeInOut};
    animation-delay: ${delay};

    &:not(:last-child) {
      margin-right: ${theme.layout.spacing.s300}px;
    }
  `
})

export const LoadingBullets = ({size = 'md', className}: Props) => {
  return (
    <ButtonLoadingWrapper className={className}>
      <LoadingBullet size={size} delay="0s" />
      <LoadingBullet size={size} delay="0.2s" />
      <LoadingBullet size={size} delay="0.4s" />
    </ButtonLoadingWrapper>
  )
}
