import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {ThemeType} from 'types/Theme'

import {RangeSizesType} from '../../../../../types/Sizes'

interface PropsType {
  /** The size of the bottom padding. Only spacing sizes from the theme are accepted */
  paddingBottom?: RangeSizesType

  /** The size of the left padding. Only spacing sizes from the theme are accepted */
  paddingLeft?: RangeSizesType

  /** The size of the right padding. Only spacing sizes from the theme are accepted */
  paddingRight?: RangeSizesType

  /** The size of the padding for all sides. Only spacing sizes from the theme are accepted. Each side will be overwritten if it is passed separately */
  padding?: RangeSizesType

  /** The size of the top padding. Only spacing sizes from the theme are accepted */
  paddingTop?: RangeSizesType

  /** The size of the horizontal padding. Only spacing sizes from the theme are accepted */
  paddingX?: RangeSizesType

  /** The size of the vertical padding. Only spacing sizes from the theme are accepted */
  paddingY?: RangeSizesType
}

// Helper function to determine padding
const resolvePadding = (
  specificPadding: RangeSizesType | undefined,
  axisPadding: RangeSizesType | undefined,
  defaultPadding: RangeSizesType,
  theme: ThemeType
): string => {
  return `${
    theme.layout.spacing[specificPadding || axisPadding || defaultPadding]
  }px`
}

export const ContentWrapper = styled.div<PropsType>(
  ({
    padding = 's000',
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingX,
    paddingY,
    theme
  }) => css`
    padding-bottom: ${resolvePadding(paddingBottom, paddingY, padding, theme)};
    padding-left: ${resolvePadding(paddingLeft, paddingX, padding, theme)};
    padding-right: ${resolvePadding(paddingRight, paddingX, padding, theme)};
    padding-top: ${resolvePadding(paddingTop, paddingY, padding, theme)};
  `
)

export default ContentWrapper
