import {path, split} from 'ramda'
import {ColorPath, ThemeType} from 'types/Theme'

/**
 * Get the color from the theme based on the color path
 */
export const getColorByPath = (theme: ThemeType, colorPath: ColorPath) =>
  colorPath ? (path(split('.', colorPath), theme.colors) as string) : undefined

export const inverseGradient = (theme: ThemeType) =>
  `linear-gradient(
      122.03deg,
      ${theme.colors.background.inverse.c800} -15.69%,
      ${theme.colors.background.inverse.c950} 64.03%
    )`
