import {useEffect} from 'react'

import {getDataDogRumInstance} from '@daedalus/core/src/analytics/components/DataDogClient'
import {useOpticks} from '@daedalus/core/src/experiments/components/OpticksProvider'

export const useAddFeatureFlagTracking = () => {
  const Opticks = useOpticks()
  useEffect(() => {
    if (Opticks) {
      Opticks.addActivateListener(data => {
        const {flagKey, variationKey} = data.decisionInfo
        getDataDogRumInstance().addFeatureFlagEvaluation(flagKey, variationKey)
      })
    }
  }, [Opticks])

  return null
}
