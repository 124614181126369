import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {SpacingPropsType} from 'types/Layout'

type Props = {
  /** Pass through classname to allow styles overrides */
  className?: string
  /** The size of the left and right margins */
  sideMargin?: SpacingPropsType
}

const DividerWrapper = styled('div')<{sideMargin: SpacingPropsType}>(
  ({theme, sideMargin}) => css`
    width: 100%;
    background: ${theme.colors.transparent};
    padding: 0 ${theme.layout.spacing[sideMargin]}px;
  `
)

export const DividerElement = styled('div')(
  ({theme}) => css`
    height: 1px;
    border-bottom: 1px solid;
    border-color: ${theme.colors.border.neutral.c100};
  `
)

export const Divider = ({sideMargin = 's000', className}: Props) => (
  <DividerWrapper sideMargin={sideMargin} className={className}>
    <DividerElement />
  </DividerWrapper>
)
