// DO NOT EDIT THIS IS AN AUTOGENERATED FILE;
import * as React from 'react'

interface IconProps {
  color?: string
  size?: string | number
}

const SvgGoogleColor = ({color = 'currentColor', size = 20}: IconProps) => (
  <svg
    viewBox="0 0 20 20"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    height={size}
  >
    <g clipPath="url(#googleColor_svg__a)">
      <rect width={20} height={20} rx={10} fill="#fff" />
      <mask
        id="googleColor_svg__b"
        style={{
          maskType: 'luminance'
        }}
        maskUnits="userSpaceOnUse"
        x={2}
        y={2}
        width={16}
        height={16}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.814 8.545h-7.628v3.091h4.39c-.409 1.964-2.12 3.091-4.39 3.091-2.679 0-4.837-2.109-4.837-4.727 0-2.618 2.158-4.727 4.837-4.727A4.8 4.8 0 0 1 13.2 6.327L15.581 4a8.258 8.258 0 0 0-5.395-2C5.646 2 2 5.564 2 10s3.647 8 8.186 8C14.279 18 18 15.09 18 10c0-.473-.074-.982-.186-1.455Z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#googleColor_svg__b)">
        <path d="M1.256 14.727V5.273L7.58 10l-6.325 4.727Z" fill="#FBBC05" />
      </g>
      <mask
        id="googleColor_svg__c"
        style={{
          maskType: 'luminance'
        }}
        maskUnits="userSpaceOnUse"
        x={2}
        y={2}
        width={16}
        height={16}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.814 8.545h-7.628v3.091h4.39c-.409 1.964-2.12 3.091-4.39 3.091-2.679 0-4.837-2.109-4.837-4.727 0-2.618 2.158-4.727 4.837-4.727A4.8 4.8 0 0 1 13.2 6.327L15.581 4a8.258 8.258 0 0 0-5.395-2C5.646 2 2 5.564 2 10s3.647 8 8.186 8C14.279 18 18 15.09 18 10c0-.473-.074-.982-.186-1.455Z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#googleColor_svg__c)">
        <path
          d="M1.256 5.273 7.58 10l2.605-2.218 8.93-1.418V1.273H1.256v4Z"
          fill="#EA4335"
        />
      </g>
      <mask
        id="googleColor_svg__d"
        style={{
          maskType: 'luminance'
        }}
        maskUnits="userSpaceOnUse"
        x={2}
        y={2}
        width={16}
        height={16}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.814 8.545h-7.628v3.091h4.39c-.409 1.964-2.12 3.091-4.39 3.091-2.679 0-4.837-2.109-4.837-4.727 0-2.618 2.158-4.727 4.837-4.727A4.8 4.8 0 0 1 13.2 6.327L15.581 4a8.258 8.258 0 0 0-5.395-2C5.646 2 2 5.564 2 10s3.647 8 8.186 8C14.279 18 18 15.09 18 10c0-.473-.074-.982-.186-1.455Z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#googleColor_svg__d)">
        <path
          d="m1.256 14.727 11.163-8.363 2.94.363 3.757-5.454v17.454H1.256v-4Z"
          fill="#34A853"
        />
      </g>
      <mask
        id="googleColor_svg__e"
        style={{
          maskType: 'luminance'
        }}
        maskUnits="userSpaceOnUse"
        x={2}
        y={2}
        width={16}
        height={16}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.814 8.545h-7.628v3.091h4.39c-.409 1.964-2.12 3.091-4.39 3.091-2.679 0-4.837-2.109-4.837-4.727 0-2.618 2.158-4.727 4.837-4.727A4.8 4.8 0 0 1 13.2 6.327L15.581 4a8.258 8.258 0 0 0-5.395-2C5.646 2 2 5.564 2 10s3.647 8 8.186 8C14.279 18 18 15.09 18 10c0-.473-.074-.982-.186-1.455Z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#googleColor_svg__e)">
        <path
          d="M19.116 18.727 7.581 10 6.093 8.91l13.023-3.637v13.454Z"
          fill="#4285F4"
        />
      </g>
    </g>
    <defs>
      <clipPath id="googleColor_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgGoogleColor
