// TODO: Use Emotion instead of global styles
import React from 'react'
import {Global} from '@emotion/react'
import {CSSObject, Interpolation} from '@emotion/styled'

import {breakpoints} from '../../../../utils/breakpoints'

const GRID_SIZES = [true, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] as const
const GUTTERS = [0, 4, 8, 12, 16, 24, 40] as const
const keys = [
  'mobileSm',
  'mobileLg',
  'desktopXs',
  'desktopSm',
  'desktopMd',
  'desktopLg'
] as const

type KeyValues = (typeof keys)[number]

type GridStyles = Record<string, CSSObject>

function up(key: KeyValues) {
  const value = typeof breakpoints[key] === 'number' ? breakpoints[key] : key
  return `@media (min-width:${value}px)`
}

function generateGrid(globalStyles: GridStyles, breakpoint: KeyValues) {
  // For the auto layouting
  const styles: GridStyles = {
    [`.grid-${breakpoint}`]: {
      flexBasis: 0,
      flexGrow: 1,
      maxWidth: '100%'
    }
  }

  // fit to the size of child component
  // eslint-disable-next-line fp/no-mutation
  styles[`.grid-${breakpoint}-fit`] = {
    flexBasis: 'auto',
    maxWidth: '100%'
  }

  // fill available space
  // eslint-disable-next-line fp/no-mutation
  styles[`.grid-${breakpoint}-fill`] = {
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
    maxWidth: '100%'
  }

  GRID_SIZES.forEach(size => {
    if (typeof size === 'boolean') {
      // Skip the first one as handle above.
      return
    }

    // Only keep 6 significant numbers.
    const width = `${Math.round((size / 12) * 10 ** 6) / 10 ** 4}%`

    // eslint-disable-next-line fp/no-mutation
    styles[`.grid-${breakpoint}-${size}`] = {
      flexBasis: width,
      maxWidth: width
    }
  })

  // No need for a media query for the first size.
  if (breakpoint === 'mobileSm') {
    Object.assign(globalStyles, styles)
  } else {
    globalStyles[up(breakpoint)] = styles
  }

  return globalStyles
}

function generateGutter() {
  type GutterStyles = Record<
    `.grid-spacing-${(typeof GUTTERS)[number]}`,
    CSSObject & {'& > .grid-item': CSSObject}
  >
  const styles: GutterStyles = {} as GutterStyles

  GUTTERS.forEach((spacing, index) => {
    if (index === 0) {
      // Skip the default style.
      return
    }

    // eslint-disable-next-line fp/no-mutation
    styles[`.grid-spacing-${spacing}`] = {
      margin: `0 -${spacing / 2}px`,
      flexBasis: `calc(100% + ${spacing}px)`,
      '& > .grid-item': {
        padding: `0 ${spacing / 2}px`
      }
    }
  })

  return styles
}

// TODO: force once via ramda?
function generateStyles() {
  const containerStyles = {
    '.grid-container': {
      boxSizing: 'border-box',
      display: 'flex',
      flexWrap: 'wrap',
      flexBasis: '100%'
    },
    '.grid-item': {
      boxSizing: 'border-box',
      flex: '0 0 auto',
      margin: '0',
      height: '100%'
    },
    '.direction-mobileSm-column': {
      flexDirection: 'column'
    },
    '.direction-mobileSm-column-reverse': {
      flexDirection: 'column-reverse'
    },
    '.direction-mobileSm-row-reverse': {
      flexDirection: 'row-reverse'
    },
    '.wrap-mobileSm-nowrap': {
      flexWrap: 'nowrap'
    },
    '.wrap-mobileSm-wrap-reverse': {
      flexWrap: 'wrap-reverse'
    },
    '.align-items-mobileSm-center': {
      alignItems: 'center'
    },
    '.align-items-mobileSm-flex-start': {
      alignItems: 'flex-start'
    },
    '.align-items-mobileSm-flex-end': {
      alignItems: 'flex-end'
    },
    '.align-items-mobileSm-baseline': {
      alignItems: 'baseline'
    },
    '.align-content-mobileSm-center': {
      alignContent: 'center'
    },
    '.align-content-mobileSm-flex-start': {
      alignContent: 'flex-start'
    },
    '.align-content-mobileSm-flex-end': {
      alignContent: 'flex-end'
    },
    '.align-content-mobileSm-space-between': {
      alignContent: 'space-between'
    },
    '.align-content-mobileSm-space-around': {
      alignContent: 'space-around'
    },
    '.justify-mobileSm-center': {
      justifyContent: 'center'
    },
    '.justify-mobileSm-flex-end': {
      justifyContent: 'flex-end'
    },
    '.justify-mobileSm-space-between': {
      justifyContent: 'space-between'
    },
    '.justify-mobileSm-space-around': {
      justifyContent: 'space-around'
    },
    ...generateGutter()
  }

  const grids = keys.reduce((acc, key) => {
    return {...acc, ...generateGrid({}, key)}
  }, {})
  return {...containerStyles, ...grids}
}

const styles = generateStyles()

const GridGlobalStyles = () => (
  <Global styles={styles as Interpolation<unknown>} />
)

export default GridGlobalStyles
