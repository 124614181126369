import React, {forwardRef, ReactNode} from 'react'
import {ClassNames, ClassNamesArg, css, useTheme} from '@emotion/react'
import styled from '@emotion/styled'

import {SpacingPropsType} from '../../../../../types/Layout'

type Props = {
  /** CSS align-content property. Sets the distribution of space between and around content items. */
  alignContent?: string
  /** CSS align-items property. Sets the align-self value on all direct children as a group. */
  alignItems?: string
  /** Content to be displayed inside the Grid */
  children: ReactNode
  /** Pass through classname to allow styles overrides */
  className?: string
  /** React ref object */
  ref?: React.RefObject<any>
  /** Whether this is a container Grid */
  container?: boolean
  /** CSS direction property. Sets the direction of the child Grid items. */
  direction?: string
  /** Theme spacing index for setting the gap size */
  gap?: SpacingPropsType
  /** CSS justify-content property. Defines how the browser distributes space between and around child Grid items. */
  justify?: string
  /** onClick function to be passed down to the Grid div */
  onClick?: () => null
  /** Theme spacing index for setting the combined gutter size */
  spacing?: SpacingPropsType
  /** Custom styling to be passed down to the Grid div */
  style?: Record<string, number | string>
  /** CSS flex-wrap property. Sets whether child Grid items are forced onto one line or can wrap onto multiple lines. */
  wrap?: string
  /** Grid column width of this Grid item for large desktop screens */
  desktopLg?: number | string
  /** Grid column width of this Grid item for medium desktop screens */
  desktopMd?: number | string
  /** Grid column width of this Grid item for small desktop screens */
  desktopSm?: number | string
  /** Grid column width of this Grid item for extra small desktop screens */
  desktopXs?: number | string
  /** Grid column width of this Grid item for small mobile screens */
  mobileSm?: number | string
  /** Grid column width of this Grid item for large mobile screens */
  mobileLg?: number | string
}

interface StyleProps {
  gap?: SpacingPropsType
}

const GridElement = styled('div')<StyleProps>(({theme, gap}) =>
  gap
    ? css`
        gap: ${theme.layout.spacing[gap]}px;
      `
    : null
)

const defaultProps = {
  alignContent: 'stretch',
  alignItems: 'stretch',
  container: false,
  direction: 'row',
  justify: 'flex-start',
  spacing: 's500',
  wrap: 'wrap'
} as Props

export const Grid = forwardRef(
  (
    {
      alignContent = defaultProps.alignContent,
      alignItems = defaultProps.alignItems,
      className,
      container = defaultProps.container,
      direction = defaultProps.direction,
      gap,
      justify = defaultProps.justify,
      spacing = defaultProps.spacing,
      wrap = defaultProps.wrap,
      mobileSm,
      mobileLg,
      desktopXs,
      desktopSm,
      desktopMd,
      desktopLg,
      children,
      onClick,
      style
    }: Props,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const theme = useTheme()
    const spacingValue = theme.layout.spacing[spacing as SpacingPropsType]

    const combinedClassName = {
      'grid-container': container,
      'grid-item': !container,
      [`direction-mobileSm-${String(direction)}`]:
        direction !== defaultProps.direction,
      [`wrap-mobileSm-${String(wrap)}`]: wrap !== defaultProps.wrap,
      [`align-items-mobileSm-${String(alignItems)}`]:
        alignItems !== defaultProps.alignItems,
      [`align-content-mobileSm-${String(alignContent)}`]:
        alignContent !== defaultProps.alignContent,
      [`justify-mobileSm-${String(justify)}`]: justify !== defaultProps.justify,
      [`grid-spacing-${String(spacingValue)}`]:
        container && spacingValue && true,
      'grid-mobileSm': false,
      [`grid-mobileSm-${String(mobileSm)}`]: mobileSm && true,
      'grid-mobileLg': false,
      [`grid-mobileLg-${String(mobileLg)}`]: mobileLg && true,
      'grid-desktopXs': false,
      [`grid-desktopXs-${String(desktopXs)}`]: desktopXs && true,
      'grid-desktopSm': false,
      [`grid-desktopSm-${String(desktopSm)}`]: desktopSm && true,
      'grid-desktopMd': false,
      [`grid-desktopMd-${String(desktopMd)}`]: desktopMd && true,
      'grid-desktopLg': false,
      [`grid-desktopLg-${String(desktopLg)}`]: desktopLg && true
    } as ClassNamesArg

    return (
      <ClassNames>
        {({cx}) => (
          <GridElement
            gap={gap}
            className={cx(combinedClassName, className)}
            style={style}
            onClick={onClick}
            ref={ref}
          >
            {children}
          </GridElement>
        )}
      </ClassNames>
    )
  }
)

// eslint-disable-next-line fp/no-mutation
Grid.displayName = 'Grid'
