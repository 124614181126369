import * as React from 'react'
import {ContextValue} from '@datadog/browser-core'
import {datadogLogs, StatusType} from '@datadog/browser-logs'
import {datadogRum} from '@datadog/browser-rum-slim'
import {mapObjIndexed} from 'ramda'

import {experimentVariationsStore} from '@daedalus/core/src/experiments/components/OpticksProvider'

import {runInIdleTime} from '../../../performance/idleUntilUrgent/runInIdleTime'

type InitializeDataDogClientParametersType = {
  appEnv?: string
  appVersion?: string
  clientToken?: string | null
  applicationId?: string | null
  sampleRate: number
  premiumSampleRate?: number
  forwardErrorsToLogs?: boolean
  service?: string
}

const DEFAULT_PRIVACY_LEVEL = 'mask-user-input'
export const DD_SITE = 'datadoghq.com'

/**
 * Fires an action to tracks that the user initiated a sign in process
 * @param event - The event name
 * @param metadata - Extra event data
 * @param level - Type of the event from StatusType
 */
export const dataDogLogEvent = (
  event: string,
  metadata: any,
  level: StatusType = StatusType.info
) => {
  datadogLogs.logger.log(event, metadata, level)
}

/**
 * Create a new logger based on a given name. Useful to compartmentalise logging into different scopes
 * @param loggerName the name of the new logger context you want to create
 * @returns a datadog logger
 */
export const createDataDogLogger = (loggerName: string) => {
  return datadogLogs.createLogger(loggerName)
}

const datadogRumMock: Pick<
  typeof datadogRum,
  'addTiming' | 'addAction' | 'addFeatureFlagEvaluation'
> = {
  addTiming(name, time) {
    return [name, time]
  },
  addAction(name, args) {
    return [name, args]
  },
  addFeatureFlagEvaluation(featureKey, featureEnabled) {
    return [featureKey, featureEnabled]
  }
}

export const getDataDogRumInstance = () => {
  return datadogRum || datadogRumMock
}

export const beforeSend = () => {
  const experiments = experimentVariationsStore.getAll(true)
  datadogRum.setGlobalContextProperty('experiments', experiments)
  return true
}

/**
 * Init Datadog client with the necessary token, app version and env
 */
export const InitializeDataDogClient = ({
  clientToken,
  appVersion,
  applicationId,
  sampleRate,
  premiumSampleRate,
  appEnv,
  forwardErrorsToLogs = false,
  service = undefined
}: InitializeDataDogClientParametersType) => {
  if (clientToken && applicationId && sampleRate > 0) {
    runInIdleTime(() => {
      datadogRum.init({
        clientToken,
        applicationId,
        sessionSampleRate: sampleRate,
        sessionReplaySampleRate: premiumSampleRate,
        version: appVersion,
        env: appEnv,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: DEFAULT_PRIVACY_LEVEL,
        site: DD_SITE,
        beforeSend,
        enableExperimentalFeatures: ['feature_flags']
      })
    })
  }

  if (clientToken) {
    runInIdleTime(() => {
      datadogLogs.init({
        clientToken,
        site: DD_SITE,
        forwardErrorsToLogs,
        service
      })

      mapObjIndexed<ContextValue, void, string>((value, key) => {
        if (value) {
          datadogLogs.setGlobalContextProperty(key, value)
        }
      })({appVersion, env: appEnv})
    })
  }

  return null
}

export default React.memo(InitializeDataDogClient)
